import React, { useEffect, useState, useCallback, useRef } from "react";
import { graphql, Link } from "gatsby";
import queryString from "query-string";
import Axios from "axios";
import { Container, Spinner } from "react-bootstrap";

// Type imports
import IRoom from "../@types/IRoom";
import IURLSearchParams from "../@types/IURLSearchParams";
import { ICheckoutProps } from "../pageHelpers/Checkout/CheckoutProps";
import { Constants, GATSBY_MOBILE_APP } from "../@types/Constants";

// Component imports
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import CheckoutFinish from "../components/common/CheckoutFinish/CheckoutFinish";
import CheckoutGuestDetails from "../components/common/CheckoutGuestDetails/CheckoutGuestDetails";
import ErrorMessageAlert from "../components/common/ErrorMessageAlert/ErrorMessageAlert";
import {
  Accomendations,
  CheckoutAlert,
  HiddenContainer,
  buildCartStackParams,
  checkSelectRoom,
  resetRoomPricing,
  getBreadCrumbStep,
  postRecentSearchIfNecessary,
  addCartStackParamsToDataLayer,
  checkDateUpdatesOnly,
} from "../pageHelpers/Checkout/CheckoutHelpers";

// Hook imports
import { useAppDispatch, useAppSelector } from "../hooks";
import useDebounce from "../hooks/useDebounce";
import { useWebFramed } from "../hooks/useWebFramed";
import { useCheckoutRefreshAvailability } from "../hooks/useCheckoutRefreshAvailability";

// Redux actions
import { setCheckout } from "../redux/slices/Checkout/checkout";
import { setInitiateReservationResponse } from "../redux/slices/Checkout/initiateReservation";
import { setSearch } from "../redux/slices/Search/search";

// Service and utility imports
import { coreGetContent } from "../services/core";
import {
  createInitiateReservationPayload,
  initiateReservation,
  cancelInitiatedReservation,
} from "../services/crs";
import { parseSearchDate } from "../services/dates";
import { isEmptyObject } from "../services/helpers";
import {
  convertArrayToObject,
  getSearchPayloadStayDates,
} from "../utils/helpers";
import { Room } from "../models/room";

const Checkout: React.FC<ICheckoutProps> = (props) => {
  const page = props.data.page;
  const location = props.location || {};
  const dispatch = useAppDispatch();
  const isWebFramed = useWebFramed();
  const STEPS = ["empty", "select_room", "guest_details", "finish"];
  const unlockBannerShow = page ? page.field_show_unlock_banner : false;
  const _site = props.data.allRlhsite.edges[0].node;

  const checkout = useAppSelector((state: any) => state.checkout);
  const initiateReservationResponse = useAppSelector(
    (state: any) => state.initiateReservationResponse
  );
  const search = useAppSelector((state: any) => state.search);
  const recentSearch = useAppSelector((state: any) => state.recentSearch);
  const crmProfile = useAppSelector((state: any) => state.member.crmProfile);
  // const prevSearchStateRef = useRef(search);
  const [checkoutReady, setCheckoutReady] = useState(false);
  const [step, setStep] = useState(checkout ? checkout.Step : "");
  const [hotel, setHotel] = useState(null);
  const [_brandCode, setBrandCode] = useState(null);
  const [currentRoomIndex, setCurrentRoomIndex] = useState(0);
  const [cartstackParams, setCartstackParams] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>("");
  const [step1page, setStep1page] = useState(false);
  const [stayOnGuestDetails, setStayOnGuestDetails] = useState(false);
  const [prevRoomCode, setPrevRoomCode] = useState(null);

  // const [loadingRates] = useCheckoutRefreshAvailability(
  //   prevRoomCode,
  //   stayOnGuestDetails,
  //   setStep,
  //   setStayOnGuestDetails
  // );

  const breadCrumbStep = getBreadCrumbStep(STEPS, step);

  const handleClearError = () => {
    setErrorMsg("");
    setShowError(false);
  };

  const handleSetCurrentRoomIndex = (index: number) => {
    setCurrentRoomIndex(index);
  };

  const handleReservationSubmitError = (message: any) => {
    const errorMetaData = `Hotel-${checkout.HotelCode}`;
    const errorMsg = (
      <ErrorMessageAlert
        errorType={Constants.ERRORSTYPE.RESERVATION}
        errorSubType={Constants.ERRORSSUBTYPE.RESRVATION.CREATE}
        message={`${message} -- ${errorMetaData}`}
      >
        <span
          id="reservation-submit-error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </ErrorMessageAlert>
    );
    setErrorMsg(errorMsg);
    setShowError(true);
    const element = document.getElementById("reservation-submit-error");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleGoToSelectRoomStep = () => {
    setStep(STEPS[1]); //'select_room');
  };

  const handleGoToGuestDetailsStep = () => {
    setStep1page(false);
    setStep(STEPS[2]);
  };

  const handleGoToFinishStep = (updatedCheckout: any) => {
    const roomIds = Object.keys(updatedCheckout.Rooms);
    const initiatePayload = createInitiateReservationPayload(
      roomIds,
      updatedCheckout,
      crmProfile?.profileType || "Bronze"
    );
    if (
      initiateReservationResponse &&
      initiateReservationResponse?.reservationTransactionId
    ) {
      // Cancelling the previous initiated reservation
      const cancelResponse = cancelInitiatedReservation(
        initiateReservationResponse?.reservationTransactionId
      );
    }
    (async () => {
      try {
        const reservationResponse = await initiateReservation(initiatePayload);
        const reservationResponses = {
          roomIds: roomIds,
          reservationTransactionId:
            reservationResponse?.reservationTransactionId || "",
        };
        dispatch(setInitiateReservationResponse(reservationResponses));
      } catch (error) {
        console.error("Error making reservations:", error.message);
      }
    })();

    setStep(STEPS[3]);
  };

  const handleOnRoomsLoad = (roomsRates: any) => {
    const isDiscountInSearch = search.groupCode || search.promotionCode;
    let offerAvailable = false;
    if (isDiscountInSearch) {
      offerAvailable =
        roomsRates && roomsRates.length && roomsRates[0].FromRatePromotional;
      const discountType = search.promotionCode ? "Promo code" : "Group code";
      if (!offerAvailable && !isWebFramed) {
        const errorMsg: any = (
          <>
            Your {discountType} <strong>{isDiscountInSearch}</strong> is
            unavailable for the selected dates. We are showing lowest available
            rates.
          </>
        );
        setErrorMsg(errorMsg);
        setShowError(true);
      }
    }
  };

  const handleSelectedRoomsSoldOut = (updatedCheckoutRooms: any) => {
    const errorMsg = (
      <>
        Selected rooms are no longer available for current search.
        <br />
        Please re-select rooms below to proceed with your booking.
      </>
    );
    setErrorMsg(errorMsg);
    setShowError(true);
    dispatch(
      setCheckout({
        ...checkout,
        Rooms: convertArrayToObject(updatedCheckoutRooms, "id"),
      })
    );
    setStep(STEPS[1]); //'select_room');
  };

  // Initialize Checkout.
  useEffect(() => {
    let didCancel = false;
    let thisBrandCode = null;
    const source = Axios.CancelToken.source();
    const fetchHotel = async (crsCode: any) => {
      let fetchedHotel = null;
      const apiUrl = `/hotels?filter[status][value]=1&filter[crs_code][value]=${crsCode}&include=brand_id`;
      const data = await coreGetContent(apiUrl, source);
      if (data.data.length) {
        if (data.included) {
          thisBrandCode = data.included[0].attributes.brand_code;
        }
        const updatedRelationships = {
          ...data.data[0].attributes?.relationships,
          brand_id: data?.included?.[0]?.attributes,
        };

        fetchedHotel = {
          ...data.data[0].attributes,
          relationships: updatedRelationships,
        };
      }
      return fetchedHotel;
    };
    (async () => {
      let thisHotel = null;
      let searchRooms = search.rooms;
      let thisCurrentIndex = searchRooms[0].id;
      let currentStep = step;
      let thisCheckout = {
        hotelLocation: search?.checkoutState?.hotelLocation,
        Brand: search?.checkoutState?.Brand,
        redemptionItem: search?.checkoutState?.redemptionItem,
      };
      let searchPayload = null;
      if (isWebFramed) {
        if (location.state !== null) {
          if (location.state.hotel) {
            thisHotel = location.state.hotel;
            if (thisHotel.relationships && thisHotel.relationships.brand_id) {
              thisBrandCode = thisHotel.relationships.brand_id.brand_code;
            }
          }
          if (location?.state?.rooms) {
            searchRooms = location.state.rooms;
            window.history.replaceState(
              { ...location.state, ...{ rooms: null } },
              ""
            );
          } else {
            if (!checkout.Rooms) {
              setStep(STEPS[1]); //'select_room');
            }
            searchRooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
          }
        }
      } else {
        if (location?.state !== null || search?.checkoutState !== null) {
          if (location?.state?.hotel || search?.checkoutState?.hotel) {
            thisHotel = location?.state?.hotel ?? search?.checkoutState?.hotel;
            if (thisHotel.relationships && thisHotel.relationships.brand_id) {
              thisBrandCode = thisHotel.relationships.brand_id.brand_code;
            }
          }
          if (location?.state?.rooms || search?.checkoutState?.rooms) {
            searchRooms = location?.state?.rooms ?? search.checkoutState.rooms;
            window.history.replaceState(
              { ...location.state, ...{ rooms: null } },
              ""
            );
          } else {
            if (!checkout.Rooms) {
              setStep(STEPS[1]); //'select_room');
            }
            searchRooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
          }
        }
      }

      if (location.search.length) {
        const qp = queryString.parse(location.search, {
          parseBooleans: true,
          arrayFormat: "index",
        });

        const queryParams = Object.keys(qp).reduce((_qp, key) => {
          _qp[key.toLowerCase()] = qp[key];
          return _qp;
        }, {} as IURLSearchParams);
        if (queryParams !== null) {
          if (queryParams.crscode) {
            thisHotel = await fetchHotel(queryParams.crscode);
            const rooms: Array<IRoom> = [];
            if (thisHotel !== null) {
              setStep1page(true);
              // Update search params to match the hotel.
              if (queryParams.rooms) {
                const _rooms = JSON.parse(queryParams.rooms);
                _rooms.forEach((room: IRoom) => {
                  const cleanedRoom = room;
                  if (cleanedRoom.adults) {
                    rooms.push(
                      new Room(
                        cleanedRoom.adults,
                        cleanedRoom.children,
                        cleanedRoom.childrenAges
                      )
                    );
                  }
                });
              }
              if (queryParams[`adult`]) {
                const adultsArr = queryParams[`adult`].split(",");
                const childrenArr = queryParams[`child`]
                  ? queryParams[`child`].split(",")
                  : [];
                const childrenAgeArr = queryParams[`childages`]
                  ? queryParams[`childages`].split(",")
                  : [];
                const roomCount = adultsArr.length;
                if (roomCount) {
                  for (let i = 0; i < roomCount; i++) {
                    const adults = parseInt(adultsArr[i] || 1) || 1;

                    const childrens = parseInt(childrenArr[i] || 0);
                    const totalGuests = adults + childrens;
                    const childrenAges = [];

                    const allRoomChildrenAges = childrenAgeArr[i]
                      ? childrenAgeArr[i].split("|")
                      : [];

                    for (let j = 0; j < childrens; j++) {
                      const ageFromUrl = allRoomChildrenAges[j];
                      const childAge =
                        ageFromUrl && ageFromUrl >= 0 && ageFromUrl < 18
                          ? ageFromUrl
                          : "";
                      childrenAges.push(childAge);
                    }
                    totalGuests <= 8 &&
                      rooms.push(new Room(adults, childrens, childrenAges));
                  }
                  searchRooms = rooms;
                }
              }
              const checkinDate = queryParams.checkin
                ? parseSearchDate(queryParams.checkin)
                : undefined;
              const checkoutDate = queryParams.checkout
                ? parseSearchDate(queryParams.checkout)
                : undefined;
              const isGroupCode = queryParams.isgroupcode
                ? queryParams.isgroupcode === true
                : false;
              const groupCode = queryParams.groupcode
                ? queryParams.groupcode.trim().toUpperCase()
                : null;
              const promoCode = queryParams.promocode
                ? queryParams.promocode.trim().toUpperCase()
                : null;
              const rateCode = isGroupCode ? groupCode : promoCode;
              const ratePlanFilterCode = queryParams.rateplanfiltercode
                ? queryParams.rateplanfiltercode.trim().toUpperCase()
                : null;
              const lengthOfStay = queryParams.los ? queryParams.los : null;
              const leadDays = queryParams.ld ? queryParams.ld : null;

              searchPayload = {
                ...search,
                searchString: thisHotel.name,
                latitude: thisHotel.lat_lon.lat,
                longitude: thisHotel.lat_lon.lon,
                rooms: rooms.length ? rooms : [new Room()],
              };

              if (checkinDate || checkoutDate || lengthOfStay || leadDays) {
                const { formedCheckin, formedCheckout } =
                  getSearchPayloadStayDates(
                    checkinDate,
                    checkoutDate,
                    lengthOfStay,
                    leadDays,
                    null
                  );
                if (formedCheckin && formedCheckout) {
                  searchPayload.checkin = formedCheckin;
                  searchPayload.checkout = formedCheckout;
                }
              }
              if (rateCode !== null) {
                if (isGroupCode) {
                  searchPayload.discount = null;
                  searchPayload.promotionCode = null;
                  searchPayload.groupCode = rateCode;
                } else {
                  searchPayload.promotionCode = rateCode;
                  searchPayload.discount = null;
                  searchPayload.groupCode = null;
                  searchPayload.ratePlanFilterCode = null;
                }
              }
              if (ratePlanFilterCode !== null) {
                searchPayload.ratePlanFilterCode = ratePlanFilterCode;
              }
            }
          }
        }
      }
      if (
        thisHotel === null ||
        thisHotel.crs_name === null ||
        thisHotel.crs_code === null
      ) {
        currentStep = STEPS[0]; //"empty";
      } else {
        const selectRoomIndex = checkSelectRoom(searchRooms);
        if (selectRoomIndex !== null || searchRooms.length == 0) {
          currentStep = STEPS[1]; //'select_room';
          thisCurrentIndex = selectRoomIndex;
        } else if (step !== STEPS[3]) {
          //finish state
          currentStep = STEPS[2]; //'guest_details';
        } else {
          currentStep = step;
        }
        thisCheckout = {
          ...checkout,
          Start: searchPayload ? searchPayload.checkin : search.checkin,
          End: searchPayload ? searchPayload.checkout : search.checkout,
          discount: searchPayload ? searchPayload.discount : search.discount,
          promotionCode: searchPayload
            ? searchPayload.promotionCode
            : search.promotionCode,
          groupCode: searchPayload ? searchPayload.groupCode : search.groupCode,
          Crs: thisHotel.crs_name,
          HotelCode: thisHotel.crs_code,
          PropertyCode: thisHotel.hotel_code,
          Rooms: convertArrayToObject(searchRooms, "id"),
          Step: currentStep,
          ratePlanFilterCode: searchPayload
            ? searchPayload.ratePlanFilterCode
            : search.ratePlanFilterCode,
          hotelLocation: search.checkoutState?.hotelLocation,
          Brand: search.checkoutState?.Brand,
          redemptionItem: search.checkoutState?.redemptionItem,
        };
      }
      if (!didCancel) {
        if (thisBrandCode !== null) {
          setBrandCode(thisBrandCode);
        }
        if (thisHotel !== null && thisHotel.crs_code !== null) {
          addCartStackParamsToDataLayer(searchRooms, search, location);
          setCartstackParams(
            buildCartStackParams(
              thisHotel.crs_code,
              search.checkin,
              search.checkout,
              searchRooms,
              search.discount,
              search.promotionCode
            )
          );
        }
        setStep(currentStep);
        setHotel(thisHotel);
        setCurrentRoomIndex(thisCurrentIndex);
        if (searchPayload) {
          await dispatch(setSearch(searchPayload));
        }
        await dispatch(setCheckout(thisCheckout));
        setCheckoutReady(true);
      }
    })();
    return () => {
      didCancel = true;
      source.cancel("Request cancelled for fetch hotel.");
    };
  }, []);

  // Watch for checkout room updates.
  useEffect(() => {
    if (checkoutReady && !isEmptyObject(checkout)) {
      const selectRoomIndex =
        checkout.Rooms && checkSelectRoom(Object.values(checkout.Rooms));
      if (selectRoomIndex !== null) {
        setCurrentRoomIndex(selectRoomIndex);
        // if (
        //   !stayOnGuestDetails ||
        //   step !== "guest_details" ||
        //   search?.rooms?.length > 1
        // ) {
        setStep(STEPS[1]); //'select_room');
        // }
      } else if (step !== STEPS[3]) {
        //finish state
        setStep(STEPS[2]); //'guest_details';
        setStep1page(false);
      }
    }
  }, [checkoutReady, checkout, search.rooms]);

  // Follow search date updates to reset checkout.
  useEffect(() => {
    let didCancel = false;
    handleClearError();
    if (checkoutReady) {
      let resetRooms = false;
      // If dates changed, reselect all rooms.
      if (
        search.checkin !== checkout.Start ||
        search.checkout !== checkout.End
      ) {
        resetRooms = true;
      } else if (search.discount !== checkout.discount) {
        resetRooms = true;
      } else if (search.promotionCode !== checkout.promotionCode) {
        resetRooms = true;
      } else if (search.groupCode !== checkout.groupCode) {
        resetRooms = true;
      }
      if (resetRooms && checkout.Rooms) {
        const resetRoomsArray = resetRoomPricing(Object.values(checkout.Rooms));
        const newCheckout = {
          ...checkout,
          Start: search.checkin,
          End: search.checkout,
          discount: search.discount,
          promotionCode: search.promotionCode,
          groupCode: search.groupCode,
          Rooms: convertArrayToObject(resetRoomsArray, "id"),
          isResubmitted: false,
        };
        if (!didCancel) {
          dispatch(setCheckout(newCheckout));
          // if (
          //   !stayOnGuestDetails ||
          //   step !== "guest_details" ||
          //   search?.rooms?.length > 1
          // ) {
          setStep(STEPS[1]); //'select_room');
          // }
          setCartstackParams(
            buildCartStackParams(
              checkout.HotelCode,
              search.checkin,
              search.checkout,
              resetRoomsArray,
              search.discount,
              search.promotionCode
            )
          );
        }
      }
    }
    return () => {
      didCancel = true;
    };
  }, [
    checkoutReady,
    search.checkin,
    search.checkout,
    search.discount,
    search.promotionCode,
    search.groupCode,
  ]);

  // Follow search rooms updates to update checkout.
  useEffect(() => {
    let didCancel = false;
    if (checkoutReady) {
      const searchRooms: IRoom[] = search.rooms;
      if (checkout.Rooms) {
        let updateRooms = false;
        const currentRooms: IRoom[] = Object.values(checkout.Rooms);
        searchRooms.forEach(function (room: IRoom, index: number) {
          const match = currentRooms.find(
            (currentRoom) => currentRoom.id === room.id
          );
          if (match !== undefined) {
            if (
              room.adults !== match.adults ||
              room.children !== match.children
            ) {
              updateRooms = true;
              currentRooms[index] = {
                ...room,
                adults: room.adults,
                children: room.children,
                room: null,
                rate: null,
                services: null,
              };
            } else {
              currentRooms[index] = match;
            }
          } else {
            currentRooms[index] = {
              ...room,
              room: null,
              rate: null,
              services: null,
            };
            updateRooms = true;
          }
        });
        // Remove any rooms from checkout object that don't match to search.
        const removeRooms: IRoom[] = [];
        currentRooms.forEach(function (room) {
          const match = searchRooms.find(
            (searchRoom) => searchRoom.id === room.id
          );
          if (match === undefined) {
            removeRooms.push(room);
          }
        });
        if (removeRooms.length) {
          removeRooms.forEach(function (room) {
            const removeIndex = currentRooms.findIndex(
              (roomItem) => roomItem.id === room.id
            );
            currentRooms.splice(removeIndex, 1);
          });
          updateRooms = true;
        }
        if (updateRooms || searchRooms.length !== currentRooms.length) {
          const newCheckout = {
            ...checkout,
            Rooms: convertArrayToObject(currentRooms, "id"),
          };
          if (!didCancel) {
            dispatch(setCheckout(newCheckout));
            addCartStackParamsToDataLayer(currentRooms, search, location);
            setCartstackParams(
              buildCartStackParams(
                checkout.HotelCode,
                checkout.Start,
                checkout.End,
                currentRooms,
                checkout.discount,
                checkout.promotionCode
              )
            );
          }
        }
      }
    }
    return () => {
      didCancel = true;
    };
  }, [checkoutReady, search.rooms]);

  useEffect(() => {
    if (step === "empty" || (checkoutReady && hotel === null)) {
      setShowError(true);
      const errorMsg = (
        <ErrorMessageAlert
          errorType={Constants.ERRORSTYPE.CHECKOUT}
          errorSubType={Constants.ERRORSSUBTYPE.CHECKOUT.HOTELSEARCH}
          message={`Please search for a hotel`}
        >
          <h2 className="text-center">Please search for a hotel.</h2>
          <Link to="/hotel-search">Search</Link>
        </ErrorMessageAlert>
      );
      setErrorMsg(errorMsg);
    }
  }, [step, checkoutReady, hotel]);

  useEffect(() => {
    let didCancel = false;
    const newCheckout = {
      ...checkout,
      Step: step,
    };
    !didCancel && checkout.Step != step && dispatch(setCheckout(newCheckout));
    typeof window !== "undefined" && window.scrollTo(0, 0);
    return () => {
      didCancel = true;
    };
  }, [step]);

  const debouncedPostRecentSearch = () => {
    (async () => {
      try {
        await postRecentSearchIfNecessary(
          crmProfile,
          checkout,
          search,
          recentSearch,
          isWebFramed,
          dispatch
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  };

  const dependencies: any = [checkout, search, crmProfile]; // The dependencies array to be passed to the useDebounce hook

  // The useDebounce hook to delay the execution of postRecentSearchIfNecessary function by 5000ms
  useDebounce(debouncedPostRecentSearch, 5000, dependencies);

  useEffect(() => {
    if (step === STEPS[3]) {
      window.history.pushState(
        { step: STEPS[3] },
        "",
        window.location.pathname
      );
    }
    const handlePopState = (event) => {
      if (event.state && event.state.step === STEPS[3]) {
        setStep(STEPS[2]);
        window.removeEventListener("popstate", handlePopState);
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [step, setStep, STEPS]);

  // useEffect(() => {
  //   const resultUpdate = checkDateUpdatesOnly(search.previousState, search);

  //   const getRoomCode = (data) => {
  //     const keys = Object.keys(data);
  //     if (keys.length > 0) {
  //       const firstKey = keys[0];
  //       if (data[firstKey] && data[firstKey].room) {
  //         return data[firstKey].room.code;
  //       }
  //     }
  //     return null;
  //   };

  //   if (
  //     resultUpdate &&
  //     checkout.Rooms &&
  //     search?.rooms?.length < 2 &&
  //     step === STEPS[2]
  //   ) {
  //     const roomCode = getRoomCode(checkout.Rooms);
  //     setPrevRoomCode(roomCode);
  //     setStayOnGuestDetails(true);
  //   } else {
  //     setStayOnGuestDetails(false);
  //   }
  // }, [search]);

  // if (loadingRates) {
  //   return (
  //     <div
  //       className="d-flex justify-content-center align-items-center"
  //       style={{ height: "100vh" }}
  //     >
  //       <Spinner animation="border" />
  //     </div>
  //   );
  // }

  if (!checkoutReady && isWebFramed) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }
  return (
    <Layout
      {...{ site: _site }}
      searchBreadCrumbStep={breadCrumbStep}
      hotel={hotel}
      showLogo
      isHotelPage
      showMerchandisingBlock={false}
      handleGoToGuestDetailsStep={handleGoToGuestDetailsStep}
    >
      <SEO title="Checkout" location={props.location} koddiTitle="Checkout" />
      <HiddenContainer
        cartstackParams={cartstackParams}
        hotel={hotel}
        unlockBannerShow={unlockBannerShow}
      ></HiddenContainer>
      <Container className="pt-5 pb-5" fluid="sm">
        {showError && errorMsg && step !== "finish" && (
          <CheckoutAlert handleClearError={handleClearError}>
            {errorMsg}
          </CheckoutAlert>
        )}
        {checkoutReady && step === "select_room" && hotel !== null && (
          <Accomendations
            hotel={hotel}
            checkout={checkout}
            handleClearError={handleClearError}
            currentRoomIndex={currentRoomIndex}
            handleSetCurrentRoomIndex={handleSetCurrentRoomIndex}
            handleOnRoomsLoad={handleOnRoomsLoad}
            step={step}
            step1page={step1page}
          ></Accomendations>
        )}
        {checkoutReady && step === "guest_details" && hotel !== null && (
          <CheckoutGuestDetails
            checkin={checkout.Start}
            checkout={checkout.End}
            rooms={checkout.Rooms}
            hotel={hotel}
            index={currentRoomIndex}
            location={location}
            checkoutStep={step}
            onBackClick={handleGoToSelectRoomStep}
            onNextClick={handleGoToFinishStep}
            stayOnPage={stayOnGuestDetails}
          />
        )}
        {checkoutReady && step === "finish" && hotel !== null && (
          <CheckoutFinish
            hotel={hotel}
            location={location}
            rooms={checkout.Rooms}
            checkoutStep={step}
            onBackClick={handleGoToGuestDetailsStep}
            onSelectedRoomsSoldOut={handleSelectedRoomsSoldOut}
            onError={handleReservationSubmitError}
            clearError={handleClearError}
            errorMsg={errorMsg}
            showError={showError}
            handleClearError={handleClearError}
          />
        )}
      </Container>
    </Layout>
  );
};
export const pageQuery = graphql`
  query CheckoutQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          machine_name
          relationships {
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphFeaturedDestinations
              ...ParagraphMediaCta
            }
          }
        }
      }
    }
    page: nodePage(title: { eq: "Hotel Search" }) {
      id
      field_show_unlock_banner
    }
  }
`;
export default Checkout;
